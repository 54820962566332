import React from "react";

import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import { BannerStandard, CtaPrimary } from "../components";
import { Result } from "antd";
import { InternalLink, InternalLinkTypes } from "../components/links";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" description={"Page not found"} />
    <BannerStandard
      title={"Page not found"}
      subTitle={"That was a human error"}
    />
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <CtaPrimary>
          <InternalLink url={InternalLinkTypes.Home}>Back Home</InternalLink>
        </CtaPrimary>
      }
    />
  </Layout>
);

export default NotFoundPage;
